import { configureStore, combineReducers } from '@reduxjs/toolkit'
import userReducer from './slices/userSlice'
import loginReducer from './slices/loginSlice'
import { persistReducer } from 'redux-persist'
import storage from 'redux-persist/lib/storage'
import voucherReducer from './slices/voucherSlice'
import inputReducer from './slices/searchInputSlice'
import hotelReducer from './slices/hotelSlice'
import systemLogSlice from './slices/systemLogSlice'
import clientReducer from './slices/clientSlice'
import langReducer from './slices/langSlice'
import emailLogSlice from './slices/emailLogSlice'
// Import other reducers if you have more

const rootReducer = combineReducers({
    user: userReducer,
    login: loginReducer,
    voucher: voucherReducer,
    input: inputReducer,
    hotel: hotelReducer,
    systemLog: systemLogSlice,
    client: clientReducer,
    language: langReducer,
    emailLog: emailLogSlice
    // Add other reducers here
})

const persistConfig = {
    key: 'root87',
    storage
}
const persistedReducer = persistReducer(persistConfig, rootReducer)

export const store = configureStore({
    reducer: persistedReducer,
    middleware: (getDefaultMiddleware) =>
        getDefaultMiddleware({
            serializableCheck: false
        })
    // Optionally, add middleware or enhancers if you need them
})

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>
export type AppDispatch = typeof store.dispatch

export default store
